

.token_box {
    position: relative;
    width: calc(100% - 42px);
    padding: 20px;
    padding-top: 45px;
    border: 1px solid #f0f0f0;
    border: 1px solid rgb(219, 219, 219);
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 20px;
}

.token_box.thin {
    padding-top: 20px;
}

.token_box.blue {
    background-color: #c7e9ff;
    border: 1px solid #4dbbff;
}

.token_box.purple {
    background-color: #f4ebff;
    border: 1px solid #9342f5;
}

.fw_split_box {
    display: inline-block;
    position: relative;
    width: calc(50% - 5px);
    /* padding: 20px; */
    /* padding-top: 45px; */
    border-radius: 10px;
    overflow: hidden;
    margin-top: -5px;
    margin-bottom: 20px;
    text-align: center;
    line-height: 50px;
    font-weight: bold;
    cursor: pointer;
}

.fw_split_box:hover {
    opacity: 0.75;
}

.fw_split_box.purple {
    background-color: #4dbbff;
    color: white;
}

.fw_split_box.gray {
    margin-left: 10px;
    background-color: #e8e8e8;
    color: black;
}

@media (max-width: 440px) {
    .fw_split_box {
        width: 100%;
        margin-left: 0px;
    }

    .fw_split_box.gray {
        margin-left: 0px;
    }
}


.token_box_desc {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 150px);
}

.token_rank_indicator {
    display: inline-block;
    vertical-align: middle;
    width: 50px;
    height: 50px;
    background-color: #f0f0f0;
    text-align: center;
}

.token_icon {
    display: inline-block;
    vertical-align: middle;
    width: 50px;
    height: 50px;
    margin-right: 10px;
    border-radius: 10px;
}

.vs_button {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 150px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    background-color: #9342f5;
    font-weight: bold;
    cursor: pointer;
    color: white;
}

.extra_spacer {
    width: 100%;
    height: 10px;
}

.tick_reason {
    width: 100%;
    margin-top: 10px;
}

.tick_icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 5px;
    vertical-align: middle;
}

.vs_button_mobile {
    position: relative;
    display: none;
    width: 100%;
    height: 50px;
    text-align: center;
    line-height: 50px;
    background-color: #9342f5;
    margin-top: 20px;
    font-weight: bold;
    color: white;
    cursor: pointer;
}

.vs_button:hover {
    opacity: 0.8;
}

.vs_button_mobile:hover {
    opacity: 0.8;
}

.rank_indicator_simple {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 25px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    font-size: 14px;
    background-color: #f0f0f0;
}

.rank_indicator_extra_text {
    position: absolute;
    top: 0px;
    left: 28px;
    height: 25px;
    line-height: 25px;
    width: calc(100% - 23px);
    padding-left: 10px;
    padding-right: 10px;
    background-color: #f0f0f0;
    font-size: 14px;
}

.rank_indicator_simple.blue {
    background-color: #4dbbff;
    font-weight: bold;
}

.rank_indicator_extra_text.blue {
    background-color: #4dbbff;
    font-weight: bold;
}

.ul_spec {
    list-style-type: none;
}

.full_img_w {
    width: 100%;
}

.visit_nsite_button {
    width: 250px;
    /* margin-left: calc(50% - 125px); */
    margin-top: 20px;
    height: 50px;
    line-height: 50px;
    color: white;
    background-color: #9342f5;
    font-weight: bold;
    text-align: center;
    /* border-radius: 10px; */
    cursor: pointer;

}

.visit_nsite_button:hover {
    opacity: 0.8;
}

.full_token_section {
    margin-top: 40px;
    margin-bottom: 40px;
}

.full_table {
    margin-left: 0px;
    width: 100%;
}

.pros_cons_container {
    vertical-align: top;
    display: inline-block;
    width: 50%;
}

@media (max-width: 640px) {

    .token_box_desc {
        width: 100%;
    }

    .vs_button {
        display: none;
    }

    .vs_button_mobile {
        display: block;
    }

    .pros_cons_container {
        display: block;
        width: 100%;
    }
}